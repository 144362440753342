@import '../../../../scss/theme-bootstrap';

// These values should match what it's in the field: under_nav_banner_height
$site-banner-custom-height-options: 30px, 40px, 50px, 60px, 70px, 80px, 90px;

// Helper classes
.header-mobile-hidden {
  @include breakpoint($mobile-only) {
    display: none !important;
  }
}

.header-pc-hidden {
  @include breakpoint($portrait-up) {
    display: none !important;
  }
}

.header-secondary-menu {
  display: none;
}

.visually-hidden {
  width: 1px !important;
  height: 1px !important;
  padding: 0;
  overflow: hidden;
  position: absolute;
  margin: -1px;
  clip: rect(0 0 0 0);
}

body.toolbar {
  &.device-pc {
    padding-top: $admin-toolbar-height;
  }
}

@mixin desktop-nav-item {
  @include font-smoothing;
  font-size: 16px;
  font-weight: bold;
  width: auto;
  float: $ldirection;
  @include swap_direction(margin, 40px 0 0 20px);
  @include breakpoint($medium-up) {
    margin-#{$ldirection}: 15px;
  }
  @include breakpoint($landscape-up) {
    margin-#{$ldirection}: 20px;
  }
}

.site-header {
  background: $white;
  line-height: 1.85em;
  // Messing things up
  position: relative;
  height: $header-height;
  @include breakpoint($portrait-up) {
    height: $header-height-desktop;
  }
  @include breakpoint($xlarge-up) {
    max-width: 100%;
    margin: 0 auto;
    z-index: 998;
    position: relative;
  }
  .coresite & {
    @if $cr20 == false {
      height: $core-header-height;
      @include breakpoint($portrait-up) {
        height: $core-header-height-desktop;
      }
    } @else {
      height: $header-height;
      @include breakpoint($portrait-up) {
        height: $header-height-desktop;
      }
    }
  }
  .contextual-links-region {
    position: static;
  }
  &__offer {
    display: block;
    &.inactive {
      height: 0;
      .site-header:not(.content-block-sitewide-banner--enabled) & {
        width: 100%;
        height: 10px;
        background-color: $color-off-black;
      }
    }
  }
  &__cart {
    display: inline-block;
    position: relative;
    z-index: 2;
    @if $hit_area_update == true {
      margin-#{$ldirection}: 0;
    } @else {
      margin-#{$ldirection}: 13px;
    }
    @include breakpoint($portrait-up) {
      margin-#{$ldirection}: 0px;
      margin-#{$rdirection}: 20px;
      margin-top: 5px;
      @if $cr20 == false {
        z-index: 7;
      }
      @if $cr20 == true {
        margin-top: 0;
        margin-#{$rdirection}: 10px;
      }
    }
    @include breakpoint($landscape-up) {
      @if $cr20 == false {
        margin-top: 6px;
      }
    }
    @include breakpoint($xlarge-up) {
      @if $cr20 == true {
        margin-#{$rdirection}: 35px;
      }
    }
    .gnav-util--cart {
      margin-#{$rdirection}: 13px;
      @if $hit_area_update == true {
        margin-#{$ldirection}: 4px;
      }
      @include breakpoint($portrait-up) {
        margin-#{$rdirection}: 0;
        @if $cr20 == true {
          > a {
            color: $color-off-black;
            &:hover,
            &:active {
              color: $color-link-hover;
            }
          }
        }
        @if $hit_area_update == true {
          margin-#{$ldirection}: 0;
        }
      }
    }
    .gnav-util__icon__cart-count {
      position: absolute;
      top: 4px;
      font-size: 11px;
      color: $white;
      text-align: center;
      width: 21px;
      line-height: get-line-height(11px, 40px);
      @include breakpoint($portrait-up) {
        color: $white;
        line-height: inherit;
        @if $cr20 == true {
          font-size: 10px;
          top: 2px;
        }
        width: 100%;
      }
      @include breakpoint($landscape-up) {
        line-height: inherit;
        @if $cr20 == false {
          font-size: 12px;
        }
      }
    }
  }
  &__content {
    background: $white;
    display: none;
    padding: 10px;
    position: absolute;
    width: 100%;
    z-index: 1;
    @include breakpoint($portrait-up) {
      margin: auto;
      @if $search_modernized_design == true {
        padding: 0 20px 30px;
      } @else {
        padding: 30px 20px;
      }
    }
    &-signin-widget {
      padding: 0;
      #{$rdirection}: 0;
      width: auto;
      @include breakpoint($medium-up) {
        margin-top: -100px;
        z-index: 9;
      }
    }
  }
  .user-loyalty {
    font-size: 14px;
    display: inline-block;
    @include breakpoint($portrait-up) {
      gap: 20px;
      display: flex;
      align-items: end;
      min-height: 28px;
    }
    @include breakpoint($landscape-up) {
      display: inline-block;
    }
    @if $cr20 == false {
      .icon {
        text-decoration: none;
        width: 20px;
        height: 20px;
        margin-#{$rdirection}: 3px;
        margin-top: -5px;
        color: $color-pure-privilege;
      }
    }
    &__program {
      font-weight: bold;
      padding: 0 10px;
      @include breakpoint($portrait-up) {
        padding: 0;
      }
      .icon--pure-privilege {
        width: 14px;
        height: 14px;
      }
    }
    &__points-join {
      margin-#{$rdirection}: 8px;
    }
    &__points-count {
      display: none;
      margin-#{$rdirection}: 8px;
    }
    &.active {
      .user-loyalty__points-join {
        display: none;
      }
      .user-loyalty__points-count {
        display: inline;
      }
    }
  }
  // flip search icon
  .icon--search {
    @include transform(rotate(90deg) scale(-1));
  }
  &__flag {
    &--img {
      vertical-align: middle;
      padding: 0 10px;
    }
  }
  &__language-picker {
    display: inline-block;
  }
  &__language {
    display: inline-block;
  }
  .salon-takeover__logo {
    img {
      height: auto;
      width: auto;
      max-height: 70px;
      max-width: 150px;
      background-color: $color-off-black;
      padding: 3px;
    }
    .salon-takeover & {
      display: block;
    }
  }

  .site-header__menu-list-content a.salon-takeover__logo {
    display: flex;
    align-items: center;
    max-width: calc(50vw - 180px);
    width: auto;
    span {
      font-size: 16px;
      font-style: normal;
      line-height: 1.9;
      font-weight: 400;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      height: 29px;
      margin-left: 5px;
      text-align: left;
      &:hover {
        color: $color-link-hover;
      }
    }
  }

  .site-header__utility-left a.site-header__utility-logo:not(.header-mobile-hidden) {
    display: inline;
  }

  .header-pc-hidden a.js-mobile-salon-takeover__label {
    display: flex;
    justify-content: center;
    width: auto;
    background-color: $color-header-white;
    border-top: 1px solid $black;
    & span {
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      white-space: nowrap;
      width: calc(100vw - $social-shoppe-height);
    }
  }

  .user-loyalty {
    &__guest,
    &__user {
      span {
        @if $cr20 == false {
          display: none;
        }
      }
    }
  }
  &__nav-right {
    @include breakpoint($portrait-up) {
      @if $cr20 == true {
        margin-top: 20px;
        margin-bottom: 14px;
        position: absolute;
        #{$rdirection}: 0;
        bottom: 0;
        display: flex;
        margin-#{$rdirection}: 10px;
      }
    }
    @include breakpoint($ipad-portrait) {
      #{$rdirection}: 0;
      margin-top: 17px;
      margin-bottom: 17px;
      margin-#{$rdirection}: 10px;
    }
    @include breakpoint($xlarge-up) {
      @if $cr20 == true {
        #{$rdirection}: calc((100% - #{$max-width-large}) / 2);
        margin-#{$rdirection}: 33px;
      }
    }
  }
  &__language-picker {
    @include breakpoint($portrait-up) {
      @if $cr20 == true {
        display: inline-block;
      }
    }
  }
  &__utility-desktop {
    @include breakpoint($portrait-up) {
      &.header-mobile-hidden {
        @if $cr20 == true {
          position: absolute;
          #{$rdirection}: 0;
          top: calc(40px + 25px);
          z-index: 6;
          display: flex;
          .no-offer & {
            top: 25px;
          }
          .social-shoppe-enabled & {
            top: $social-shopping-bar-height-large;
          }
          .site-header__utility-item {
            color: $color-off-black;
            vertical-align: middle;
            font-size: 15px;
            &:hover,
            &:active {
              color: $color-link-hover;
            }
            .icon {
              margin-top: -2px;
              width: 15px;
              height: 15px;
              @include breakpoint($ipad-portrait) {
                margin-top: 0;
              }
            }
          }
          .site-header__utility-item--professional-url,
          .site-header__utility-item--store-locator-url {
            #get_facts & {
              display: none;
            }
          }
        }
      }
    }
    @include breakpoint($portrait-up) {
      &.header-mobile-hidden {
        #{$rdirection}: 0;
        .site-header {
          &__utility-item {
            font-size: 0;
            &--professional-url {
              font-size: inherit;
            }
          }
        }
      }
    }
    @include breakpoint($landscape-up) {
      &.header-mobile-hidden {
        .site-header {
          &__utility-item {
            font-size: 15px;
          }
        }
      }
    }
    &.header-mobile-hidden {
      @include breakpoint($xlarge-up) {
        @if $cr20 == true {
          #{$rdirection}: calc((100% - #{$max-width-large}) / 2);
        }
      }
    }
  }
  &__utility {
    height: $header-height;
    line-height: get-line-height(15px, $header-height);
    vertical-align: middle;
    background: $white;
    color: $color-off-black;
    position: relative;
    &-right {
      float: $rdirection;
      .salon-takeover & {
        .site-header__utility-item:not(.js-nav-content) {
          display: none;
        }
      }
    }
    &-signin {
      .elc-user-state-logged-in & {
        display: none;
      }
    }
    &-item,
    .gnav-util__icon {
      color: $color-off-black;
      @if $hit_area_update == true {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin: 0 4px;
        line-height: inherit;
        text-align: center;
      } @else {
        margin-#{$ldirection}: 13px;
      }
      @include breakpoint($portrait-up) {
        @if $cr20 == true {
          color: $color-off-black;
          margin-#{$ldirection}: 7px;
        }
        @if $hit_area_update == true {
          @include swap_direction(margin, 0 0 0 7px);
          width: auto;
          height: auto;
        }
      }
      @include breakpoint($landscape-up) {
        @if $hit_area_update == true {
          line-height: normal;
        }
      }
      &:hover {
        text-decoration: none;
      }
    }
    .gnav-util__icon {
      margin-#{$ldirection}: 0;
    }
    &-hamburger {
      @if $hit_area_update == true {
        margin-#{$ldirection}: 13px;
      }
    }
    .gnav-util__icon--cart {
      @if $hit_area_update == true {
        margin: 0;
      }
    }
    &-logo {
      @if $hit_area_update == true {
        margin: 0 4px;
        line-height: normal;
        font-size: unset;
        width: auto;
      } @else {
        margin: 0 5px;
        font-size: 20px;
        line-height: get-line-height(20px, 40px);
      }
      .salon-takeover & {
        display: none;
      }
      &.salon-takeover__logo {
        img {
          height: 24px;
          vertical-align: middle;
          width: auto;
          background-color: $color-off-black;
        }
        .salon-takeover & {
          display: inline-block;
        }
      }
    }
    .icon {
      width: 20px;
      height: 20px;
    }
    .icon--hamburger {
      width: 18px;
      height: 18px;
      top: -1px;
      position: relative;
    }
    .icon--logo--mobile {
      width: 98px;
      height: 24px;
    }
  }
  &__main {
    @include transition(transform 0.25s ease);
    overflow: visible;
    position: fixed;
    top: 0;
    #{$ldirection}: 0;
    width: 100%;
    color: $color-off-black;
    font-size: 15px;
    z-index: 100;
    border-bottom: 1px solid $color-belgian-linen;
    background-color: $white;
    @include breakpoint($portrait-up) {
      max-width: 100%;
    }
    body.toolbar.device-pc & {
      top: $admin-toolbar-height;
    }
    &.menu-position {
      position: absolute;
    }
    .site-header-formatter--sticky .content-block-sitewide-banner--enabled & {
      @include transform(translateY(-#{$content-block-sitewide-banner-height}));
      @include breakpoint($portrait-up) {
        @include transform(translateY(-#{$content-block-sitewide-banner-height-desktop}));
      }
    }
    // Hide the sticky Nav when scrolling down.
    .site-header-formatter--hidden.site-header-formatter--sticky & {
      @include transform(translateY(-100%));
    }
  }
  &__promo {
    float: $rdirection;
    margin-top: 9px;
    @include breakpoint($portrait-up) {
      margin-top: 7px;
      margin-#{$rdirection}: 20px;
      @if $cr20 == true {
        margin-top: 0;
        margin-#{$rdirection}: 10px;
      }
    }
    .guest-content {
      font-size: 14px;
      display: inline-block;
    }
    &-loyalty {
      position: relative;
      z-index: 2;
      @include breakpoint($portrait-up) {
        display: flex;
        align-items: end;
        gap: 20px;
        margin-#{$rdirection}: 20px;
        min-height: 28px;
      }
      @include breakpoint($landscape-up) {
        display: block;
        margin-#{$rdirection}: 0;
      }
      @include breakpoint($landscape-up) {
        display: block;
        margin-#{$rdirection}: 0;
      }
    }
  }
  &__menu {
    @include transition(transform 0.4s);
    @include transform(translateX(-100%));
    position: fixed;
    z-index: 7;
    top: 0;
    display: block;
    height: 100%;
    min-height: 50vh;
    width: 100%;
    color: $color-off-black;
    @include breakpoint($portrait-up) {
      @include transform(none);
      background: $white;
      position: relative;
      min-height: 0;
      padding-bottom: 0;
      height: 70px;
      z-index: 5;
      @if $cr20 == true {
        height: auto;
      }
    }
    &.active {
      @include transform(translateX(0));
      // Note: We are using content dim AND this background
      background: $color-overlay--dark;
      display: block;
      @include breakpoint($portrait-up) {
        background: $white;
      }
    }
    &.menu-position {
      overflow-y: scroll;
    }
    .user-loyalty {
      display: inline-block;
      .gnav-updates--enabled & {
        font-weight: 500;
      }
      .reward_text {
        @include breakpoint($small-down) {
          display: none;
        }
      }
      &.active {
        @include breakpoint($small-down) {
          line-height: 1.5;
          width: 94%;
          margin-top: 5px;
        }
      }
    }
    &-wrapper {
      background: $white;
      width: 92%;
      height: auto;
      padding-bottom: 30px;
      -webkit-overflow-scrolling: touch;
      position: relative;
      .gnav-updates--enabled & {
        width: 100%;
        height: 100%;
        padding-bottom: 0;
      }
      @include breakpoint($portrait-up) {
        @include transform(translateX(0));
        padding-bottom: 0;
        background: transparent;
        width: 100%;
        overflow: visible;
      }
    }
    &-desktop {
      float: $ldirection;
      margin-#{$rdirection}: 5px;
      @include breakpoint($medium-up) {
        margin-#{$rdirection}: 0;
      }
      @include breakpoint($portrait-up) {
        @if $cr20 == true {
          float: none;
          margin: 0 auto;
          padding: 90px 0 0;
          display: inline-block;
          text-align: center;
          z-index: 2;
        }
      }
      @include breakpoint($landscape-up) {
        min-height: 51px;
        @if $cr20 == true {
          padding: 89px 0 4px;
        }
      }
      .menu--lvl-1 {
        @include breakpoint($portrait-up) {
          @if $cr20 == true {
            display: flex;
          }
        }
      }
    }
    &-loyalty {
      font-size: 16px;
      height: 40px;
      line-height: get-line-height(16px, 40px);
      vertical-align: middle;
      text-align: $rdirection;
      padding: 0 $header-padding;
      .gnav-updates--enabled & {
        height: 93px;
        text-align: $ldirection;
        padding: 0 15px;
        font-weight: 500;
        border-bottom: 1px solid $color-belgian-linen;
      }
      &-greeting {
        .gnav-updates--enabled & {
          height: 48px;
          font-size: 24px;
          line-height: get-line-height(24px, 32px);
          padding-top: 16px;
        }
      }
      &__program {
        font-weight: bold;
      }
      .site-header__cart {
        display: none;
      }
      .user-loyalty__points {
        color: $color-gray;
      }
      // Don't show join link on mobile
      .user-loyalty__points-join {
        display: inline-block;
        @include breakpoint($portrait-up) {
          display: inline-block;
        }
      }
      &.active {
        @include breakpoint($small-down) {
          height: 50px;
          .gnav-updates--enabled & {
            height: 93px;
          }
          .site-header__menu-close {
            width: 5%;
            .gnav-updates--enabled & {
              width: 16px;
            }
          }
        }
      }
    }
    &-item {
      .gnav-updates--enabled & {
        font-size: 14px;
        font-weight: 500;
      }
    }

    // Gnav close on mobile
    .site-header__menu-close {
      color: $color-off-black;
      float: $ldirection;
      .gnav-updates--enabled & {
        float: $rdirection;
        position: absolute;
        top: 21px;
        #{$rdirection}: 21px;
        width: 16px;
        height: 16px;
      }
      @if $hit_area_update == true {
        width: 24px;
        text-align: center;
      }
      .icon--close {
        width: 16px;
        height: 16px;
        .gnav-updates--enabled & {
          stroke: $black;
          position: absolute;
        }
      }
    }
    a {
      @include breakpoint($portrait-up) {
        color: $color-off-black;
      }
      @include breakpoint($medium-up) {
        font-size: 10px;
      }
      @include breakpoint($large-up) {
        font-size: 14px;
      }
      > a {
        @if $cr20 == true {
          @include breakpoint($medium-up) {
            line-height: 1;
            font-size: 15px;
          }
          @include breakpoint($large-up) {
            font-size: 15px;
          }
        }
      }
      &:hover {
        text-decoration: none;
        @include breakpoint($portrait-up) {
          color: $color-link-hover;
        }
      }
    }

    // Mobile site nav
    .site-header__menu-list--mobile {
      .nav-hidden,
      .menu__item--tout {
        display: none;
      }
      // Styling per menu level
      .menu--lvl-1 {
        @include transition(all 0.3s);
        font-size: 17px;
        .menu__item--lvl-1 {
          padding: 7px $header-padding 3px;
          background: $color-header-white;
          > .menu__item-trigger {
            position: relative;
            &::after {
              @include svg-icon-inline-mask('caret--down');
              @include transform(rotate(-90deg));
              content: '';
              position: absolute;
              #{$rdirection}: -5px;
              top: 4px;
              background-color: $color-off-black;
              width: 17px;
              height: 17px;
            }
          }
          &.active {
            background: $white;
            > .menu__item-trigger::after {
              @include transform(rotate(0));
              #{$rdirection}: 1px; // Visual alignment with rotated arrow
            }
            > a:hover {
              color: $gray;
            }
          }

          // On mobile nav, we have a border separating the "black" section from the "white section"
          // This is the cutoff between shopping menu items and editorial / marketing menu items
          &.menu__item--category + .menu__item--link {
            border-top: 2px solid $color-gray;
            padding-top: 15px;
          }
          &.menu__item--link {
            background: $white;
          }
          a {
            display: block;
            @if $hit_area_update == true {
              line-height: 2;
            }
            &:hover {
              color: $color-off-black;
            }
          }
        }
        @if $hit_area_update == true {
          .menu__item--lvl-3 {
            a {
              line-height: 2.1;
              margin: 4px 0;
            }
          }
        }
        .menu__content--lvl-1 {
          font-size: 13px;
          padding: 10px $header-padding;
        }
      }
      .menu--lvl-2 {
        .menu__item--lvl-2 {
          font-size: 16px;
          &.active {
            a:hover {
              color: $gray;
            }
          }
        }
      }
      .menu--lvl-3 {
        padding-#{$ldirection}: $header-padding;
        .menu__item--lvl-3 {
          font-size: 16px;
          &.active {
            a:hover {
              color: $gray;
            }
          }
        }
      }
    }

    // Desktop site nav
    .site-header__menu-list--desktop {
      padding: 0 15px;
      max-width: $header-max-width;
      margin: auto;
      .site-header__trustmark-wrapper {
        max-width: $header-max-width;
        position: relative;
        top: 10px;
        @include breakpoint($portrait-up) {
          @if $cr20 == true {
            display: none;
          }
        }
        .salon-takeover & {
          display: none;
        }
      }
      .site-header__trustmark {
        position: absolute;
        #{$rdirection}: 10px;
        width: 50px;
        @include breakpoint($medium-up) {
          #{$rdirection}: 0;
        }
        @include breakpoint($landscape-up) {
          #{$rdirection}: 10px;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
      // Styling per menu level
      .site-header__menu-logo {
        font-size: 55px;
        line-height: 1;
        float: $ldirection;
        color: $color-off-black;
        margin-#{$rdirection}: 10px;
        @include breakpoint($medium-up) {
          margin-#{$rdirection}: 0;
          font-size: 36px;
          line-height: 1.8;
        }
        @if $cr20 == true {
          @include breakpoint($portrait-up) {
            width: 226px;
            position: absolute;
            top: 32px;
            z-index: 6;
            // Leave 'left' without RTL for 'transform' support
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            &.header-mobile-hidden {
              .site-header__main .site-header__nav & {
                margin-top: 25px;
                .icon--logo--mobile {
                  width: 226px;
                  height: 55px;
                }
              }
            }
          }
        }
        @include breakpoint($large-up) {
          font-size: 55px;
          line-height: 1;
        }
        @include breakpoint($landscape-up) {
          margin-#{$rdirection}: 10px;
        }
        .icon--logo--mobile {
          @include breakpoint($medium-up) {
            width: 148px;
            height: 36px;
          }
          @include breakpoint($large-up) {
            width: 226px;
            height: 55px;
          }
        }
        margin-top: 8px;
        .icon--logo {
          @include breakpoint($medium-up) {
            width: 89px;
            height: 36px;
          }
          @include breakpoint($large-up) {
            width: 136px;
            height: 55px;
          }
        }
        .salon-takeover & {
          float: $rdirection;
          @if $cr20 == true {
            @include breakpoint($portrait-up) {
              float: none;
            }
          }
        }
        &.salon-takeover__logo {
          float: $ldirection;
          @if $cr20 == true {
            @include breakpoint($portrait-up) {
              position: absolute;
              float: none;
              transform: unset;
              #{$ldirection}: 30px;
              width: auto;
              margin-top: 0;
            }
          }
        }
      }
      .menu__item--tout {
        width: 40%;
        display: inline-block;
        vertical-align: top;
        @if $cr20 == true {
          width: auto;
        } @else {
          width: 40%;
        }
        img {
          max-width: 100%;
        }
      }
      .menu--lvl-1,
      .site-header__menu-item {
        @include desktop-nav-item;
      }
      .menu--lvl-1 {
        @include breakpoint($portrait-up) {
          @if $cr20 == true {
            margin: 0 auto;
            float: none;
            font-size: 16px;
            line-height: 1;
            margin: 20px auto 15px;
          }
        }
      }
      .site-header__menu-item {
        margin-#{$ldirection}: 0;
        margin-top: 38px; // Icons should be 38, regular text is 40
        @include breakpoint($portrait-up) {
          @if $cr20 == true {
            display: inline-block;
            margin-top: 37px;
            font-size: 20px;
            position: relative;
            z-index: 3;
          }
        }
        &.active {
          color: $color-link-hover;
        }
        .icon--search,
        .icon--search2 {
          width: 20px;
          height: 20px;
        }
      }
      .menu__item--lvl-1 {
        @include breakpoint($medium-up) {
          margin-#{$rdirection}: 10px;
        }
        @include breakpoint($portrait-up) {
          @if $cr20 == true {
            margin-#{$rdirection}: 25px;
            line-height: 1;
          }
        }
        display: inline-block;
        background: transparent;
        margin-#{$rdirection}: 15px;
        &.active {
          background: transparent;
          > .menu__item-trigger {
            color: $color-link-hover;
          }
          .menu__item--lvl-2 {
            .menu__content--lvl-2 .menu__item-trigger {
              margin-bottom: 0;
            }
          }
        }
      }
      .menu__content--lvl-1 {
        padding: 0;
        position: absolute;
        background: $white;
        top: 100%;
        @if $cr20 == true {
          width: auto;
          #{$ldirection}: auto;
        } @else {
          #{$ldirection}: 0;
          width: 100%;
        }
        // nav-hidden only hides on the first level
        &.nav-hidden {
          display: none;
        }
      }
      .menu--lvl-2 {
        width: 100%;
        font-size: 0;
        padding: 15px 10px;
        font-weight: normal;
        max-width: 1255px; // Line up with logo
        margin: auto;
        text-align: $ldirection;
        @if $cr20 == true {
          display: flex;
        } @else {
          display: block;
        }
        .menu__item--lvl-2 {
          display: inline-block;
          padding: 0 15px;
          font-size: 16px;
          vertical-align: top;
          @if $cr20 == true {
            width: auto;
          } @else {
            width: 20%;
          }
        }
        .menu__item-trigger {
          color: $color-off-black;
          &:hover {
            cursor: default;
          }
        }
        a {
          color: $color-off-black;
        }
      }
      .menu__item--lvl-3 {
        @include breakpoint($portrait-up) {
          line-height: 1.3;
        }
        a {
          color: $black;
          &:hover {
            font-weight: bold;
          }
        }
      }
      .site-header__menu-language-country {
        @include breakpoint($portrait-up) {
          float: $ldirection;
          position: relative;
          z-index: 3;
        }
      }

      // Shop Menu Subnav
      .menu__subnav,
      .menu__subsection {
        font-size: 16px;
      }
      .menu__subnav {
        font-size: 0;
        border-bottom: 1px solid $color-light-gray;
        padding: 0 10px 8px;
        margin: 0 -10px;
        .nav-hidden {
          display: none;
        }
        .menu__item--lvl-2 {
          width: auto;
          &.active {
            .menu__item-trigger {
              color: $color-off-black;
            }
          }
          .menu__content--lvl-2 {
            display: none;
          }
          .menu__item-trigger {
            color: $header-subnav-color;
            font-weight: bold;
            &:hover {
              cursor: pointer;
              color: $color-off-black;
            }
          }
        }
      }
      .menu__subsections {
        padding: 5px 10px;
      }
      .menu__subsection {
        display: none;
        &.active {
          display: block;
        }
        .nav-hidden {
          display: block;
        }
        .menu__content--lvl-1 {
          position: relative;
        }
        .menu__item--lvl-1 {
          display: block;
        }

        // The subnav needs the first top level to be 100% width
        .menu__item--lvl-2 {
          padding-top: 0;
          padding: 0;
          width: 100%;
          // The level 2 within that can be 20% to make a 5 column
          .menu__item--lvl-2 {
            width: 20%;
            padding-top: 0;
          }
          .menu__item-trigger {
            display: none;
          }
          .menu__content--lvl-2 .menu__item-trigger {
            display: block;
            color: $black;
            margin-bottom: 6px;
          }
        }
      }
    }
    .close-icon {
      display: none;
    }
  }
  &__menu-list-content {
    @if $cr20 == true {
      @include breakpoint($portrait-up) {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .checkout-back,
  .mobile-checkout-back {
    display: none;
    svg {
      display: none;
    }
  }

  $social-shoppe-height: 40px;

  &.is-social-shoppe .site-header__utility.header-pc-hidden {
    height: $header-height + $social-shoppe-height;
  }

  // Different use-cases to set the height to the header when the
  // .site-header-offer-banner--enabled OR
  // .site-header-under-nav-banner--enabled OR
  // .content-block-sitewide-banner--enabled are in place
  &.content-block-sitewide-banner--enabled {
    height: $header-height + $content-block-sitewide-banner-height;
    @include breakpoint($portrait-up) {
      height: $header-height-desktop + $content-block-sitewide-banner-height-desktop;
    }
  }
  &.site-header-offer-banner--enabled {
    height: $header-height + $offer-banner-height;
    @include breakpoint($mobile-only) {
      &.is-social-shoppe {
        height: $header-height + $offer-banner-height + $social-shoppe-height;
      }
    }
    @include breakpoint($portrait-up) {
      height: $header-height-desktop + $offer-banner-height;
    }
    .coresite & {
      @if $cr20 == false {
        height: $core-header-height + $offer-banner-height;
        @include breakpoint($mobile-only) {
          &.is-social-shoppe {
            height: $core-header-height + $offer-banner-height + $social-shoppe-height;
          }
        }
        @include breakpoint($portrait-up) {
          height: $core-header-height-desktop + $offer-banner-height;
        }
      } @else {
        height: $header-height;
        @include breakpoint($mobile-only) {
          &.is-social-shoppe {
            height: $header-height + $social-shoppe-height;
          }
        }
        @include breakpoint($portrait-up) {
          height: $header-height-desktop;
        }
      }
    }
  }
  &.site-header-under-nav-banner--enabled {
    height: $header-height + $site-banner-height;
    @include breakpoint($mobile-only) {
      &.is-social-shoppe {
        height: $header-height + $site-banner-height + $social-shoppe-height;
      }
    }
    @include breakpoint($portrait-up) {
      height: $header-height-desktop + $site-banner-height;
    }
    @each $custom-height in $site-banner-custom-height-options {
      &.site-header-under-nav-banner--#{$custom-height} {
        height: $header-height + $custom-height;
        @include breakpoint($mobile-only) {
          &.is-social-shoppe {
            height: $header-height + $custom-height + $social-shoppe-height;
          }
        }
        @include breakpoint($portrait-up) {
          height: $header-height-desktop + $custom-height;
        }
      }
    }
    .coresite & {
      @if $cr20 == false {
        height: $core-header-height + $site-banner-height;
        @include breakpoint($mobile-only) {
          &.is-social-shoppe {
            height: $core-header-height + $site-banner-height + $social-shoppe-height;
          }
        }
        @include breakpoint($portrait-up) {
          height: $core-header-height-desktop + $site-banner-height;
        }
      } @else {
        height: $header-height;
        @include breakpoint($mobile-only) {
          &.is-social-shoppe {
            height: $header-height + $social-shoppe-height;
          }
        }
        @include breakpoint($portrait-up) {
          height: $header-height-desktop;
        }
      }
    }
  }
  &.site-header-offer-banner--enabled.site-header-under-nav-banner--enabled:not(.content-block-sitewide-banner--enabled) {
    height: $header-height + $offer-banner-height + $site-banner-height;
    @include breakpoint($mobile-only) {
      &.is-social-shoppe {
        height: $header-height + $offer-banner-height + $site-banner-height + $social-shoppe-height;
      }
    }
    @include breakpoint($portrait-up) {
      height: $header-height-desktop + $offer-banner-height + $site-banner-height;
    }
    @each $custom-height in $site-banner-custom-height-options {
      &.site-header-under-nav-banner--#{$custom-height} {
        height: $header-height + $custom-height + $site-banner-height;
        @include breakpoint($mobile-only) {
          &.is-social-shoppe {
            height: $header-height + $custom-height + $site-banner-height + $social-shoppe-height;
          }
        }
        @include breakpoint($portrait-up) {
          height: $header-height-desktop + $custom-height + $site-banner-height;
        }
      }
    }
    .section-avhair-quiz & {
      height: $header-height + $offer-banner-height;
      @include breakpoint($portrait-up) {
        height: $header-height-desktop + $offer-banner-height + $site-banner-height;
      }
    }
  }
  &.site-header-offer-banner--enabled.content-block-sitewide-banner--enabled:not(.site-header-under-nav-banner--enabled) {
    height: $header-height + $site-banner-height + $content-block-sitewide-banner-height;
    @include breakpoint($portrait-up) {
      height: $header-height-desktop + $site-banner-height +
        $content-block-sitewide-banner-height-desktop;
    }
  }
  &.site-header-under-nav-banner--enabled.content-block-sitewide-banner--enabled:not(.site-header-offer-banner--enabled) {
    height: $header-height + $offer-banner-height + $content-block-sitewide-banner-height;
    @include breakpoint($mobile-only) {
      &.is-social-shoppe {
        height: $header-height + $offer-banner-height + $content-block-sitewide-banner-height +
          $social-shoppe-height;
      }
    }
    @include breakpoint($portrait-up) {
      height: $header-height-desktop + $offer-banner-height +
        $content-block-sitewide-banner-height-desktop;
    }
    @each $custom-height in $site-banner-custom-height-options {
      &.site-header-under-nav-banner--#{$custom-height} {
        height: $header-height + $custom-height + $content-block-sitewide-banner-height;
        @include breakpoint($mobile-only) {
          &.is-social-shoppe {
            height: $header-height + $custom-height + $content-block-sitewide-banner-height +
              $social-shoppe-height;
          }
        }
        @include breakpoint($portrait-up) {
          height: $header-height-desktop + $custom-height +
            $content-block-sitewide-banner-height-desktop;
        }
      }
    }
  }
  &.site-header-offer-banner--enabled.site-header-under-nav-banner--enabled.content-block-sitewide-banner--enabled {
    height: $header-height + $offer-banner-height + $site-banner-height +
      $content-block-sitewide-banner-height;
    @include breakpoint($mobile-only) {
      &.is-social-shoppe {
        height: $header-height + $offer-banner-height + $site-banner-height +
          $content-block-sitewide-banner-height + $social-shoppe-height;
      }
    }
    @include breakpoint($portrait-up) {
      height: $header-height-desktop + $offer-banner-height + $site-banner-height +
        $content-block-sitewide-banner-height-desktop;
    }
    @each $custom-height in $site-banner-custom-height-options {
      &.site-header-under-nav-banner--#{$custom-height} {
        height: $header-height +
          $custom-height +
          $site-banner-height +
          $content-block-sitewide-banner-height;
        @include breakpoint($mobile-only) {
          &.is-social-shoppe {
            height: $header-height + $custom-height + $site-banner-height +
              $content-block-sitewide-banner-height + $social-shoppe-height;
          }
        }
        @include breakpoint($portrait-up) {
          height: $header-height-desktop + $custom-height + $site-banner-height +
            $content-block-sitewide-banner-height-desktop;
        }
      }
    }
  }
  &.gnav-updates--enabled {
    .site-header__menu {
      padding-bottom: 60px;
      @include breakpoint($portrait-up) {
        padding-bottom: 0;
      }
      .site-header__menu-close {
        float: $rdirection;
      }
      .site-header__menu-list--desktop {
        .menu--lvl-2 {
          justify-content: center;
          position: relative;
        }
      }
      & .menu__item--category a {
        &:hover {
          @include breakpoint($portrait-up) {
            color: $color-off-black;
          }
        }
      }
    }
    .menu__item--category {
      a {
        display: flex;
        align-items: center;
        height: 100%;
      }
    }
    .menu__item--tout a {
      height: auto;
    }
    .site-header__menu-list--mobile {
      .menu--lvl-1 {
        font-size: 16px;
        .menu__item--lvl-1 {
          padding: 0 16px;
          margin-top: 12px;
          background: $white;
          > .menu__item-trigger {
            &::after {
              width: 16px;
              height: 16px;
              -webkit-mask-position: center 4px;
              mask-position: center 4px;
              position: unset;
            }
          }
          &.menu__item--link {
            > .menu__item-trigger {
              &::after {
                display: none;
              }
            }
          }
          &.active {
            > a:hover {
              color: $color-off-black;
            }
          }
          &.no-active a {
            color: $color-link-hover;
            &::after {
              background-color: $color-link-hover;
            }
          }
          a {
            display: flex;
            justify-content: space-between;
            font-weight: 700;
            height: 40px;
            margin-top: 12px;
          }
        }
      }
      .menu__content--lvl-1 {
        padding-#{$ldirection}: 16px;
        padding: 0;
      }
      .menu--lvl-2 {
        margin-#{$ldirection}: 16px;
        .menu__item--lvl-2 {
          &.active {
            a:hover {
              color: $color-off-black;
            }
          }
          > .menu__item-trigger {
            &::after {
              @include svg-icon-inline-mask('caret--down');
              @include transform(rotate(-90deg));
              content: '';
              background-color: $color-off-black;
              width: 16px;
              height: 16px;
              -webkit-mask-position: center 4px;
              mask-position: center 4px;
            }
          }
          &.active {
            background: $white;
            > .menu__item-trigger::after {
              @include transform(rotate(0));
              #{$rdirection}: 1px;
            }
          }
        }
      }
      .menu--lvl-3 {
        padding-#{$ldirection}: 16px;
        .menu__item--lvl-4 {
          padding-#{$ldirection}: 16px;
          a {
            font-weight: 400;
          }
        }
      }
    }
    .site-header__menu-list--desktop {
      .menu__content--lvl-1 {
        #{$ldirection}: 0;
        width: 100%;
      }
      .menu--lvl-1 {
        margin: 15px 0 0;
        .close-icon--2 {
          display: block;
          position: absolute;
          top: 15px;
          #{$rdirection}: -15px;
          border-color: transparent;
          padding: 0;
          border-width: 0;
          background: 0 0;
          min-width: auto;
          svg.icon {
            width: 16px;
            height: 16px;
          }
        }
      }
      .menu__item--lvl-1 {
        &.active {
          > .menu__item-trigger {
            color: $color-off-black;
          }
        }
        &.no-active a {
          color: $color-link-hover;
        }
      }
      @for $i from 1 through 5 {
        .menu__item--column-#{$i} {
          grid-column-start: $i;
        }
      }
      .menu--lvl-3 {
        grid-template-rows: repeat(11, auto);
        grid-auto-columns: minmax(0, 1fr);
        display: grid;
        grid-auto-flow: column;
        width: max-content;
      }
      .menu--lvl-2 {
        .menu__item--lvl-2 {
          display: flex;
          flex-direction: column;
          font-weight: 700;
          flex: 1;
        }
        .menu__item--tout {
          padding: 0 16px;
          max-width: 50%;
        }
      }

      .menu__item--lvl-3 {
        @include breakpoint($portrait-up) {
          margin-#{$ldirection}: 16px;
          height: 32px;
        }
      }
      .menu__item--lvl-4 {
        font-weight: 400;
        @include breakpoint($portrait-up) {
          display: flex;
          margin-#{$ldirection}: 32px;
          height: 32px;
          align-items: center;
          margin-#{$ldirection}: 32px;
        }
      }
      .menu__item-trigger {
        height: 32px;
        display: flex;
        align-items: center;
      }
    }
    .site-header__customer-section {
      display: flex;
      flex-direction: column;
      padding: 15px $header-padding 0;
      .customer-section-label {
        font-size: 16px;
        font-weight: 500;
        color: $black;
      }
      .customer-section-link {
        font-size: 14px;
        text-decoration: underline;
        color: $black;
        letter-spacing: 0.005em;
      }
    }
    .site-header__secondary-links {
      height: 40px;
      svg {
        height: 24px;
        width: 24px;
        margin-#{$rdirection}: 10px;
      }
      &-wrapper {
        display: flex;
        flex-direction: column;
        padding: 15px $header-padding;
        font-size: 16px;
        font-weight: 500;
        gap: 12px;
        border-top: 1px solid $color-belgian-linen;
      }
    }
    .site-header__menu-wrapper {
      height: 100%;
      overflow-y: auto;
      padding-bottom: 35px;
      width: 100%;
      @include breakpoint($portrait-up) {
        padding-bottom: 0;
        overflow: unset;
      }
    }
    .site-header__sticky-shopping-bag {
      align-items: center;
      background: $color-off-black;
      bottom: 0;
      color: $white;
      display: flex;
      height: 60px;
      justify-content: space-between;
      padding: 0 $header-padding;
      position: fixed;
      width: 100%;
      .icon--bag {
        fill: $white;
        height: 16px;
        width: 16px;
      }
      .shopping-bag {
        align-items: center;
        display: flex;
        &__counter-bracket--open {
          margin-#{$rdirection}: -3px;
        }
        &__counter-bracket--closed {
          margin-#{$ldirection}: -3px;
        }
      }
      .shopping-bag-text,
      .shopping-bag-counter-bracket,
      .shopping-bag-qty {
        font-size: 16px;
        font-weight: 500;
      }
      .shopping-bag-text {
        @include swap_direction(margin, 0 5px 0 16px);
      }
      .shopping-bag-link {
        color: $white;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.005em;
        text-decoration: underline;
      }
    }
    .site-header__customer-section {
      display: flex;
      flex-direction: column;
      padding: 15px $header-padding 0;
      .customer-section-label {
        font-size: 16px;
        font-weight: 500;
        color: $black;
      }
      .customer-section-link {
        font-size: 14px;
        text-decoration: underline;
        color: $black;
        letter-spacing: 0.005em;
      }
    }
    .user-loyalty__program {
      font-weight: normal;
    }
  }
}

.elc-social-shoppe {
  background: $color-off-black;
  height: $social-shopping-bar-height;
  @include breakpoint($portrait-up) {
    height: $social-shopping-bar-height-large;
  }
  a {
    display: block;
    height: 100%;
    border: none;
    text-decoration: none;
    font-size: 14px;
    text-transform: none;
    color: $color-white;
  }
  &__wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    padding: 0 15px;
    margin: auto;
  }
  &__logo,
  &__logo img,
  &__text {
    display: block;
    width: auto;
    height: 100%;
  }
  &__text {
    display: flex;
    align-items: center;
    margin-#{$rdirection}: 5px;
    font-weight: 400;
    @include breakpoint($portrait-up) {
      font-size: 17px;
    }
    svg {
      fill: $color-white;
      display: inline-block;
      width: 1rem;
      height: 12px;
      margin-#{$ldirection}: 3px;
      margin-top: -2px;
    }
  }
}
